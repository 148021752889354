<template>
  <div class="paneOne">
    <a-row :gutter="16" class="mb-16 rowClass">
      <a-col :span="12">
        <div class="titleBack"><span class="borL"></span>基本信息</div>
          <a-row :gutter="16">
            <a-col :span="24">
              <div class="userMes">
                <div class="left">注册时间</div>
                <div class="right">{{datailData.createTime}}</div>
              </div>
              <div class="userMes">
                <div class="left">open ID</div>
                <div class="right">{{datailData.openId}}</div>
              </div>
              <div class="userMes">
                <div class="left">来源渠道</div>
                <div class="right">{{datailData.channelSource}}</div>
              </div>
              <div class="userMes">
                <div  class="left">订阅类型</div>
                <div  class="right">{{datailData.packageName}}</div>
              </div>
              <div class="userMes">
                <div  class="left">订阅到期时间</div>
                <div  class="right">{{datailData.memberExpireTime}}</div>
              </div>
              <div class="userMes">
                <div  class="left">最后登录时间</div>
                <div  class="right">{{datailData.lastLoginTime}}</div>
              </div>
              <div class="userMes">
                <div  class="left">微信</div>
                <div  class="right" v-if="datailData.unionId">已绑定</div>
                <div  class="right" v-else>未绑定</div>
              </div>
              <div class="userMes">
                <div  class="left">地区</div>
                <div  class="right">{{datailData.ipAddress}}</div>
              </div>
            </a-col>
          </a-row>
      </a-col>

      <a-col :span="12">
        <div class="titleBack"><span class="borL"></span>使用概况</div>
        <a-row :gutter="16">
          <a-col :span="12">
            <div class="overview">
              <div class="glod">
                <div class="mun">{{usageDetails.todoNum}} <span class="unit">个</span></div>
                <div class="yue">创建待办总数</div>
              </div>
              <div class="icon">
                <FileTextOutlined class=" activeColor"/>
              </div>
            </div>

          </a-col>
          <a-col :span="12">
            <div class="overview">
              <div class="glod">
                <div class="mun">{{usageDetails.unFinishNum}} <span class="unit">个</span></div>
                <div class="yue">未完成待办总数</div>
              </div>
              <div class="icon">
                <FileTextOutlined class=" activeColor"/>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <div class="overview">
              <div class="glod">
                <div class="mun">{{usageDetails.expireNum}} <span class="unit">个</span></div>
                <div class="yue">逾期待办总数</div>
              </div>
              <div class="icon">
                <FileTextOutlined class=" activeColor"/>
              </div>
            </div>

          </a-col>
          <a-col :span="12">
            <div class="overview">
              <div class="glod">
                <div class="mun">{{usageDetails.momentNum}} <span class="unit">条</span></div>
                <div class="yue">创建时刻总数</div>
              </div>
              <div class="icon">
                <FileTextOutlined class=" activeColor"/>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <div class="overview">
              <div class="glod">
                <div class="mun">{{usageDetails.contactNum}} <span class="unit">个</span></div>
                <div class="yue">联系人总数</div>
              </div>
              <div class="icon">
                <FileTextOutlined class=" activeColor"/>
              </div>

            </div>
          </a-col>

          <a-col :span="12">

          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import { Row, Col,  } from 'ant-design-vue';
import {
  FileTextOutlined,
} from '@ant-design/icons-vue';
import {reactive, toRefs} from "vue";
import userApi from "@/api/user";
import {useRoute} from "vue-router";
export default {
  components: {
    ARow: Row,
    ACol: Col,
    FileTextOutlined,
  },
  setup() {
    const route = useRoute();
    const { id } = route.params
    const state = reactive({
      list: [
        {
          mun: 36,
          title: '创建待办总数',
        },
        {
          mun: 6,
          title: '未完成待办总数',
        },
        {
          mun: 2,
          title: '逾期待办总数',
        },
        {
          mun: 56,
          title: '创建时刻总数',
        },
        {
          mun: 1256,
          title: '联系人总数',
        },

      ],
      datailData: {},
      usageDetails: {},

    });

    // 根据用户Id 查询用户详情数据
    userApi.getUserInfoById('', id).then(res => {
      if (res) {
        state.datailData = res
      }
    });

    // 查询当前用户使用情况
    userApi.getUsageDetails('', id).then(res => {
      if (res) {
        state.usageDetails = res
      }
    });


    return {
      ...toRefs(state),

    }

  },

}
</script>

<style scoped lang="less">
.paneOne {
  height: calc(100vh - 374px);
  //overflow-y: auto;
}
.userMes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //width: 100%;
  width: 93%;
  margin: 20px;
.left {
  width: 50%;
  margin-right: 10px;
}
.right {
  width: 50%;
  text-align: left;
}

}
.titleBack {
  background: #eeeeee;
  //border-left: 2px solid #FF7B00;
  padding: 8px 8px;
  font-weight: 550;
  font-size: 16px;
  .borL {
    border-left: 2px solid #FF7B00;
    margin-right: 8px;
  }

}
.overview {
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 20px;
  .glod {
    //padding: 20px 0px;
    width: 85%;
    border-radius: 4px;
    padding-right: 5px;
    .mun {
      font-size: 28px;
      font-weight: 500;
      word-wrap: break-word;
    }

    .unit {
      font-size: 14px;
      font-weight: 0;
    }
    .yue {
      color: #999999;
    }
  }
  .icon {
    width: 14%;
    font-size: 32px;
  }

}


</style>