<template>
  <div class="iss-main-grid">
    <!--     分页 -->
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap">
      <grid
          ref="gridRef"
          allow-selection
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ x: 1640, y: gridHeight }"
      >
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record">
            <template #goDetail>
              <div class="primary-text cursor-p" @click="handleGotoView($event, record)">详情</div>
            </template>
          </operation>
        </template>

        <template #packageType="{ record }">
          <div v-if="record.packageType === 0">订阅套餐</div>
          <div v-if="record.packageType === 1">金币充值</div>
        </template>

        <template #paymentType="{ record }">
          <div v-if="record.paymentType === 'wx'">微信</div>
          <div v-if="record.paymentType === 'zfb'">支付宝</div>
        </template>

        <template #payerTotal="{ record }">
          <div v-if="record.payerTotal">
         {{record.payerTotal > 0 ? record.payerTotal/100 : record.payerTotal}}
          </div>
          <div v-else>0</div>
        </template>

        <template #payStatus="{ text }">
          <a-tag v-if="text === 2" color="success">支付成功</a-tag>
          <a-tag v-else-if="text === 3" color="error">支付失败</a-tag>
          <a-tag v-else-if="text === 1" color="warning">未支付</a-tag>
          <a-tag v v-else-if="text === 4" color="default">支付关闭</a-tag>
        </template>

      </grid>
    </div>
  </div>
  <recharge-view
      v-model:visible="visible"
      :init="activeItem"
      @fnOk="handleView"
  />
</template>

<script>
import { reactive, ref, toRefs, } from 'vue';
// import { Row, Col, } from 'ant-design-vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import { downloadExcel } from '@/utils';
// import {useRouter} from "vue-router";
import { message } from 'ant-design-vue';
import rechargeView from "@/views/user/components/rechargeView";
import SearchForm from '@/components/searchForm';
import ordersApi from "@/api/orders";
import {useRoute} from "vue-router";

export default {
  components: {
    // ARow: Row,
    // ACol: Col,
    Operation,
    Grid,
    rechargeView,
    SearchForm,

  },
  setup() {
    const gridRef = ref();
    const route = useRoute();
    const { id } = route.params

    const state = reactive({
      search: {},
      TagList: [],
      activeKey: '1',
      visible: false,
      activeItem: {},

    });

    return {
      gridRef,
      ...toRefs(state),
      items: [
        //  需要补上日期时间筛选
        {
          key: 'date',
          timeKey: ['startCreateTime', 'endCreateTime'],
          label: '日期范围',
          type: 'rangePicker',
        },
        {
          key: 'packageType',
          label: '支付类型',
          type: 'select',
          dataset: [
            { label: '套餐订阅', value: 1 },
            { label: '金币充值', value: 0 },
          ],
        },
        {
          key: 'paymentType',
          label: '支付方式',
          type: 'select',
          dataset: [
            { label: '微信', value: 'wx' },
            { label: '支付宝', value: 'zfb' },
          ],
        },
        {
          key: 'payStatus',
          label: '订单状态',
          type: 'select',
          dataset: [
            { label: '未支付', value: 1 },
            { label: '支付成功', value: 2 },
            { label: '支付失败', value: 3 },
            { label: '支付关闭', value: 4 },
          ],
        },
      ],
      columns: [
        { dataIndex: 'packageTitle', title: '订单名称', width: 150, ellipsis: true },
        { dataIndex: 'createTime', title: '订单创建时间', width: 150, ellipsis: true },
        { dataIndex: 'packageName', title: '支付项目', width: 120, ellipsis: true,
          slots: { customRender: 'packageName' },
        },
        { dataIndex: 'packageType', title: '支付类型', width: 180, ellipsis: true,
          slots: { customRender: 'packageType' },
        },
        { dataIndex: 'orderNo', title: '订单号', width: 220, ellipsis: true },
        { dataIndex: 'position', title: '支付方式', width: 120, ellipsis: true,
          slots: { customRender: 'paymentType' },
        },
        {
          dataIndex: 'payerTotal',
          title: '金额',
          width: 180,
          slots: { customRender: 'payerTotal' },
          ellipsis: true,
        },
        { dataIndex: 'payStatus', title: '订单状态', width: 120, ellipsis: true,
          slots: { customRender: 'payStatus' },
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'export',
          label: '导出全部数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: () => {
            ordersApi
                .export('contact:export', {userId: id})
                .then(({ name, data }) => downloadExcel(name, data));
                // .then((data) => downloadExcel('用户订阅充值数据.xlsx', data));

          },
        },
        {
          type: 'export',
          label: '导出批量数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: (ids) => {
            if (ids.length) {
              ordersApi
                  .export('contact:export', {userId: id, idList:[...ids]})
                  .then(({ name, data }) => downloadExcel(name, data));
            } else {
              message.info('请至少选中一条数据！');
            }

          },
        },
      ],
      options: [
        {
          type: 'goDetail',
          slotName: 'goDetail',
          title: '详情',
          permission: 'user:goDetail',
          fnClick: ({ id }) => {
            // router.push({
            //   name: '',
            //   query: {
            //     id,
            //   },
            // });
            console.log(id,);
          },
        },
      ],
      url: `${ordersApi.pThreeUrl}`+ `?userId=${id}`,
      gridHeight: document.body.clientHeight - 548,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleView: (value) => {
        state.visible = value
      },
      handleGotoView: ($event, record)=> {
        // console.log('record', record)
        // 查询金币流水
        ordersApi.orderInfo('', record.id).then(res => {
          if (res) {
            // console.log('res金币流水', res)
            state.activeItem = res
            // console.log('dddd', state.activeItem)
          }
        });
        state.visible = true
      },

    }

  },

}
</script>

<style scoped lang="less">
.iss-main-grid {
  padding: 0px 10px;
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}

.statistics-card {
  border-radius: 4px;
  padding: 20px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
  .statistics-title {
    //padding: 10px 12px;
    width: 85%;
    font-size: 16px;
    word-wrap: break-word;
  }
  .statistics-item {
    //padding: 12px 20px;
    //width: 100%;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
  }
  .imgBox {
    width: 15%;
    .icon {
      font-size: 28px;
    }
  }
}
.statistics-card[flex-attr] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontStyle {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  //font-weight: 500;
  color: #313d5f;
  line-height: 24px;
}
// 个人信息
.userMes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .left {
    width: 36%;
    margin-right: 10px;
  }
  .right {
    width: 64%;
    text-align: left;
  }

}
.glod {
  text-align: center;
  background: #fff7ea;
  padding: 20px 0px;
  border-radius: 4px;
  .mun {
    color: #FF7B00;
  }
}


</style>