<template>
  <div class="iss-main-grid">
    <!--     分页 -->
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap">
      <grid
          ref="gridRef"
          allow-selection
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ x: 1000, y: gridHeight }"
      >
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #avatar="{ record }">
          <div  @click="handleName(record)">
            <a-avatar v-if="record.avatar" :src="setAvatar(record.avatar)" :size="30" />
            <a-avatar :size="30" v-else>
              <template #icon><UserOutlined /></template>
            </a-avatar>
          </div>
        </template>
        <template #userName="{ record }">
          <div  @click="handleName(record)" class="activeColor" v-if="record.userName">{{record.userName}}</div>
          <div  @click="handleName(record)" v-else>暂无</div>
        </template>

        <template #subscribeStatus="{ record }">
          <div  v-if="record.subscribeStatus === 1">订阅</div>
          <div v-if="record.subscribeStatus === 0">到期</div>
        </template>
        <template #status="{ record }">
          <div v-if="record.status === 1">注册</div>
          <div v-if="record.status === 0">注销</div>
        </template>

        <template #beginnerGuide="{ record }">
          <div v-if="record.beginnerGuide === 1">已激活</div>
          <div v-if="record.beginnerGuide === 0">未激活</div>
        </template>
        <template #goldNum="{ record }">
          <!--        数量样式处理   -->
          <div class="activeColor">
            +
            <span>{{record.goldNum}}</span>
          </div>

        </template>

        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
      </grid>
    </div>
  </div>



</template>

<script>
import { reactive, ref, toRefs, } from 'vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import { message,Avatar } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import userApi from "@/api/user";
import {useRoute} from "vue-router";
import {downloadExcel, setAvatar} from "@/utils";
import {
  UserOutlined,
} from '@ant-design/icons-vue';

export default {
  components: {
    // ARow: Row,
    // ACol: Col,
    Operation,
    Grid,
    SearchForm,
    AAvatar: Avatar,
    UserOutlined,

  },
  setup() {
    // const router = useRouter();
    const gridRef = ref();
    const route = useRoute();
    const { id } = route.params
    const state = reactive({
      search: {},
      TagList: [],
      activeKey: '1',

    });

    return {
      setAvatar,
      gridRef,
      ...toRefs(state),
      items: [
        //  需要补上日期时间筛选
        {
          key: 'date',
          timeKey: ['beginTime', 'endTime'],
          label: '日期范围',
          type: 'rangePicker',
        },
        {
          key: 'status',
          label: '用户状态',
          type: 'select',
          dataset: [
            { label: '注册', value: 1 },
            { label: '注销', value: 0 },
          ],
        },
        {
          key: 'subscribeStatus',
          label: '订阅状态',
          type: 'select',
          dataset: [
            { label: '订阅', value: 1 },
            { label: '到期', value: 0 },
          ],
        },
        {
          key: 'beginnerGuide',
          label: '激活状态',
          type: 'select',
          dataset: [
            { label: '已激活', value: 1 },
            { label: '未激活', value: 0 },
          ],
        },

      ],
      columns: [
        { dataIndex: 'avatar', title: '头像', width: 120, ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        { dataIndex: 'userName', title: '昵称', width: 120, ellipsis: true,
          slots: { customRender: 'userName' },
        },
        { dataIndex: 'mobile', title: '手机号', width: 120, ellipsis: true },
        { dataIndex: 'memberExpireTime', title: '订阅到期时间', width: 180, ellipsis: true,
          slots: { customRender: 'memberExpireTime'},
        },
        { dataIndex: 'goldNum', title: '奖励金币数量', width: 180, ellipsis: true,
          slots: { customRender: 'goldNum' },
        },
        { dataIndex: 'subscribeStatus', title: '订阅状态', width: 180, ellipsis: true,
          slots: { customRender: 'subscribeStatus'},
        },
        { dataIndex: 'status', title: '用户状态', width: 120, ellipsis: true,
          slots: { customRender: 'status' }, },
        { dataIndex: 'beginnerGuide', title: '激活状态', width: 180, ellipsis: true,
          slots: { customRender: 'beginnerGuide'},
        },
        { dataIndex: 'createTime', title: '邀请时间', width: 180, ellipsis: true },
        // {
        //   key: 'id',
        //   title: '操作',
        //   fixed: 'right',
        //   width: 60,
        //   slots: { customRender: 'operation' },
        // },
      ],
      btnOperation: [
        {
          type: 'export',
          label: '导出全部数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: () => {
            userApi.exportOrderUserList('', {userId:id, exportCheckStatus: 1})
                .then(({ name, data }) => downloadExcel(name, data));
          },
        },
        {
          type: 'export',
          label: '导出批量数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: ids => {
            if (ids.length) {
            userApi.exportOrderUserList('',{ userId:id, userIds: [...ids], exportCheckStatus: 0 })
                .then(({ name, data }) => downloadExcel(name, data));
            } else {
              message.info('请至少选中一条数据！');
            }
          },
        },
      ],
      options: [
        // {
        //   type: 'view',
        //   label: '查看',
        //   icon: 'EyeTwoTone',
        //   permission: 'contact:view',
        //   fnClick: (record) => {
        //     console.log('点击查看')
        //     state.visible = true
        //     state.activeItem = record
        //
        //   },
        // },
      ],
      url: `${userApi.getOrderUserList}`+ `?userId=${id}`,
      gridHeight: document.body.clientHeight - 548,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      // handleName: (record) => {
        // return router.push(`/user/${record.id}`)
      // },
    }

  },

}
</script>

<style scoped lang="less">
.iss-main-grid {
  padding: 0px 10px;
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}

.statistics-card {
  border-radius: 4px;
  padding: 20px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
  .statistics-title {
    //padding: 10px 12px;
    width: 85%;
    font-size: 16px;
    word-wrap: break-word;
  }
  .statistics-item {
    //padding: 12px 20px;
    //width: 100%;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
  }
  .imgBox {
    width: 15%;
    .icon {
      font-size: 28px;
    }
  }
}
.statistics-card[flex-attr] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontStyle {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  //font-weight: 500;
  color: #313d5f;
  line-height: 24px;
}
// 个人信息
.userMes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .left {
    width: 36%;
    margin-right: 10px;
  }
  .right {
    width: 64%;
    text-align: left;
  }

}
.glod {
  text-align: center;
  background: #fff7ea;
  padding: 20px 0px;
  border-radius: 4px;
  .mun {
    color: #FF7B00;
  }
}


</style>