<template>
  <div class="iss-main-grid">
    <!--    数据总览  -->
    <div class="statistics-card mb-16">
      <a-row :gutter="16" class="mb-16 rowClass">
        <a-col :span="10">
          <a-row :gutter="16">
            <a-col :span="6">
              <a-avatar v-if="datailData.avatar" :src="setAvatar(datailData.avatar)" :size="100" />
              <a-avatar :size="100" v-else>
                <template #icon><UserOutlined /></template>
              </a-avatar>
            </a-col>
            <a-col :span="18">
              <a-row :gutter="16">
                <a-col :span="12">
                  <div class="userMes">
                    <div class="left">昵称</div>
                    <div class="right">{{datailData.userName}}</div>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="userMes">
                    <div  class="left">手机号</div>
                    <div  class="right">{{datailData.mobile}}</div>
                  </div>
                </a-col>
              </a-row>

              <a-row :gutter="16">
                <a-col :span="12">
                  <div class="userMes">
                    <div class="left">用户ID</div>
                    <div class="right">{{datailData.id}}</div>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="userMes">
                    <div  class="left">邮箱</div>
                    <div  class="right">{{datailData.email}}</div>
                  </div>
                </a-col>
              </a-row>

              <a-row :gutter="16">
                <a-col :span="12">
                  <div class="userMes">
                    <div  class="left">性别</div>
                    <div  class="right" v-if="datailData.sex === '1'">男</div>
                    <div  class="right" v-if="datailData.sex === '0'">女</div>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="userMes">
                    <div  class="left">订阅状态</div>
                    <div  class="right" v-if="datailData.subscribeStatus === 1">订阅</div>
                    <div  class="right" v-if="datailData.subscribeStatus === 0">到期</div>
                  </div>
                </a-col>
              </a-row>

            </a-col>
          </a-row>
        </a-col>
        <a-col :span="14">
          <a-row :gutter="16">
            <a-col :span="8">
              <div class="glod">
                <div class="mun fn-18">{{datailData.goldNum ? datailData.goldNum : 0}}</div>
                <div class="yue">金币余额</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="glod">
                <div class="mun fn-18">{{datailData.orderUserNum  ? datailData.orderUserNum : 0 }}</div>
                <div class="yue">邀请用户</div>
              </div>

            </a-col>
            <a-col :span="8">
              <!--           用户昵称  -->
              <div class="glod">
                <div class="mun fn-16">{{datailData.orderUserName ? datailData.orderUserName : '暂无'}}</div>
                <div class="yue">上级用户</div>
              </div>

            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>

<!--   tab  -->
    <div class="iss-grid-wrap mt-16">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="基本信息" />
        <a-tab-pane key="2" tab="邀请用户" />
        <a-tab-pane key="3" tab="订阅充值" />
        <a-tab-pane key="4" tab="金币流水" />
      </a-tabs>
      <div>
        <template v-if="activeKey === '1'">
          <iss-pane-one></iss-pane-one>
        </template>
        <template v-else-if="activeKey === '2'">
          <iss-pane-two></iss-pane-two>
        </template>
        <template v-else-if="activeKey === '3'">
          <iss-pane-three></iss-pane-three>
        </template>
        <template v-else-if="activeKey === '4'">
          <iss-pane-four></iss-pane-four>
        </template>

      </div>
    </div>

  </div>
</template>

<script>
import {reactive, toRefs, } from 'vue';
import { Row, Col, Tabs, Avatar, } from 'ant-design-vue';
import issPaneOne from "@/views/user/paneOne";
import issPaneTwo from "@/views/user/paneTwo";
import issPaneThree from "@/views/user/paneThree";
import issPaneFour from "@/views/user/paneFour";
import {useRoute} from "vue-router";
import userApi from "@/api/user";
import { setAvatar } from '@/utils';
import {
  UserOutlined,
} from '@ant-design/icons-vue';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    issPaneOne,
    issPaneTwo,
    issPaneThree,
    issPaneFour,
    AAvatar: Avatar,
    UserOutlined,

  },
  setup() {
    const route = useRoute();
    const { activeKey} = route.query;
    const { id } = route.params
    const state = reactive({
      activeKey: activeKey ? activeKey : '1',
      datailData: {}

    });

    // 根据用户Id 查询用户详情数据
    userApi.getUserInfoById('', id).then(res => {
      if (res) {
        state.datailData = res

      }
    });




    return {
      activeKey,
      ...toRefs(state),
      setAvatar,

    }

  },

}
</script>

<style scoped lang="less">
.iss-main-grid {
  padding: 0px 10px;
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.paneOne {
  margin: 20px;
}

.statistics-card {
  border-radius: 4px;
  padding: 20px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
  .statistics-title {
    //padding: 10px 12px;
    width: 85%;
    font-size: 16px;
    word-wrap: break-word;
  }
  .statistics-item {
    //padding: 12px 20px;
    //width: 100%;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
  }
  .imgBox {
    width: 15%;
    .icon {
      font-size: 28px;
    }
  }
}
.statistics-card[flex-attr] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontStyle {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  //font-weight: 500;
  color: #313d5f;
  line-height: 24px;
}
// 个人信息
.userMes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  .left {
    //width: 36%;
    width: 66px;
    margin-right: 10px;
  }
  .right {
    width: 64%;
    text-align: left;
    word-wrap: break-word;
  }

}
.glod {
  text-align: center;
  background: #fff7ea;
  padding: 20px 0px;
  border-radius: 4px;
  font-weight: 550;
  .mun {
    color: #FF7B00;
  }
  .yue {
    //font-weight: 550;
  }
}
.iss-grid-wrap /deep/ .ant-tabs .ant-tabs-bar{
  border-bottom: none;
  font-size: 16px;
  font-weight: 550;
}

</style>